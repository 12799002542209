<template>
  <div class="meslist">
    <div class="list-container">
      <div class="tu-por">
        <ul class="list_ml">
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    请你在今天19:00前及时交付今天的日报。内容包括：1.总结今天完成了哪些计划表，还有哪些计划表没有完成，原因是什么，预计什么时间能做完；2.明天要完成哪些事情。请同步更新计划表中任务的状态。
                    <a
                      target="_blank"
                      href="#"
                      >进入总结</a
                    >
                  </span>
                </span>
                <div class="date_ml">4小时前</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    请你在本月28号19:00前及时交付本月月报。内容包括：1.总结本月完成了哪些计划表，还有哪些计划表没有完成，原因是什么，预计什么时间能完成；2.下月的计划和要完成哪些事情。请同步更新计划表中任务的状态。
                    <a
                      target="_blank"
                      href="#"
                      >进入总结</a
                    >
                  </span>
                </span>
                <div class="date_ml">4小时前</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    请你在今天19:00前及时交付今天的日报。内容包括：1.总结今天完成了哪些计划表，还有哪些计划表没有完成，原因是什么，预计什么时间能做完；2.明天要完成哪些事情。请同步更新计划表中任务的状态。
                    <a
                      target="_blank"
                      href="#"
                      >进入总结</a
                    >
                  </span>
                </span>
                <div class="date_ml">昨天 17:05</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    请你在今天19:00前及时交付今天的日报。内容包括：1.总结今天完成了哪些计划表，还有哪些计划表没有完成，原因是什么，预计什么时间能做完；2.明天要完成哪些事情。请同步更新计划表中任务的状态。
                    <a
                      target="_blank"
                      href="#"
                      >进入总结</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月24日 17:08</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    请你在本周周五19:00前及时交付本周周报。内容包括：1.总结本周完成了哪些计划表，还有哪些计划表没有完成，原因是什么，预计什么时间能完成；2.下周的计划和要完成哪些事情。请同步更新计划表中任务的状态。
                    <a
                      target="_blank"
                      href="#"
                      >进入总结</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月24日 17:08</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    Tita 小助手为你呈上本周工作小结，赶紧看看超越了多少同事！
                    <a
                      target="_blank"
                      href="https://www.tita.com/weekStatisticRoute?redisKey=544736_500476869_20220620"
                      >查看详情</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月24日 17:05</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    请你在今天19:00前及时交付今天的日报。内容包括：1.总结今天完成了哪些计划表，还有哪些计划表没有完成，原因是什么，预计什么时间能做完；2.明天要完成哪些事情。请同步更新计划表中任务的状态。
                    <a
                      target="_blank"
                      href="#"
                      >进入总结</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月23日 17:05</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    请你在今天19:00前及时交付今天的日报。内容包括：1.总结今天完成了哪些计划表，还有哪些计划表没有完成，原因是什么，预计什么时间能做完；2.明天要完成哪些事情。请同步更新计划表中任务的状态。
                    <a
                      target="_blank"
                      href="#"
                      >进入总结</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月22日 17:05</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    请你在今天19:00前及时交付今天的日报。内容包括：1.总结今天完成了哪些计划表，还有哪些计划表没有完成，原因是什么，预计什么时间能做完；2.明天要完成哪些事情。请同步更新计划表中任务的状态。
                    <a
                      target="_blank"
                      href="#"
                      >进入总结</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月21日 17:06</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    请你在今天19:00前及时交付今天的日报。内容包括：1.总结今天完成了哪些计划表，还有哪些计划表没有完成，原因是什么，预计什么时间能做完；2.明天要完成哪些事情。请同步更新计划表中任务的状态。
                    <a
                      target="_blank"
                      href="#"
                      >进入总结</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月20日 17:06</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    Tita 小助手为你呈上本周工作小结，赶紧看看超越了多少同事！
                    <a
                      target="_blank"
                      href="https://www.tita.com/weekStatisticRoute?redisKey=544736_500476869_20220613"
                      >查看详情</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月17日 17:05</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #b9cc4f; text-align: center"
                  original-title="会计主管"
                  data-body="会计主管"
                  data-original-title=""
                  >会</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 会计主管 -->
                    <span>会计主管</span>
                  </a>

                  <span class="content_ml">
                    将目标
                    <strong>形成良好的产品口碑效应，获得客户转介绍</strong
                    >交给你负责了
                  </span>
                  <a
                    href="javascript:void(0);"
                    okr-id="9381039"
                    data-name="okr-push"
                    data-messagetype="41"
                    >查看详情</a
                  >
                </span>
                <div class="date_ml">6月13日 18:54</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <!-- E周报，项目，目标提醒更新进展消息合并 -->
                  <span class="content_ml ereport message-merging">
                    提醒你 请尽快为关键成果添加任务或项目执行。
                  </span>
                  <a
                    href="javascript:void(0);"
                    data-messagetype="96"
                    data-messageid="33901925"
                    data-type="0"
                    data-id=""
                    data-date="2022/06/11 18:31"
                    data-name="react-push"
                    >查看详情</a
                  >
                </span>
                <div class="date_ml">6月11日 18:31</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    Tita 小助手为你呈上本周工作小结，赶紧看看超越了多少同事！
                    <a
                      target="_blank"
                      href="https://www.tita.com/weekStatisticRoute?redisKey=544736_500476869_20220606"
                      >查看详情</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月10日 17:03</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f79c6f; text-align: center"
                  original-title="app主管"
                  data-body="app主管"
                  data-original-title=""
                  >app</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- app主管 -->
                    <span>app主管</span>
                  </a>

                  <span class="content_ml">
                    请及时更新进展，包括当前阶段性成果、工作障碍点。—“新手项目使用教程”
                    <a
                      target="_blank"
                      href="https://www.tita.com/u/500476869/Home#work?work_id=8928197&amp;app_id=1&amp;app_type=1&amp;detail_type=task"
                      >查看详情</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月10日 15:18</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f79c6f; text-align: center"
                  original-title="app主管"
                  data-body="app主管"
                  data-original-title=""
                  >app</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- app主管 -->
                    <span>app主管</span>
                  </a>

                  <span class="content_ml">
                    将
                    <a
                      href="https://www.tita.com/workMobileRoute?workId=8928197"
                      class="notice-task-delete"
                      >新手项目使用教程</a
                    >中的 任务 <strong>sdfsdfsa</strong> 删除了。
                  </span>
                </span>
                <div class="date_ml">6月10日 15:16</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f79c6f; text-align: center"
                  original-title="app主管"
                  data-body="app主管"
                  data-original-title=""
                  >app</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- app主管 -->
                    <span>app主管</span>
                  </a>

                  <span class="content_ml">
                    将项目
                    <strong>新手项目使用教程-副本</strong>
                    中里程碑<strong>fasdfasdf</strong>删除了
                    <a
                      target="_blank"
                      href="https://www.tita.com/SiteUrlByRoute/500528187/SiteUrlByRoute/WorkRoute?workId=9381896&amp;appId="
                      >查看详情</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月10日 15:10</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f5b168; text-align: center"
                  original-title="张天哲"
                  data-body="张天哲"
                  data-original-title=""
                  >张</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- 张天哲 -->
                    <span>张天哲</span>
                  </a>

                  <span class="content_ml">
                    【新手项目使用教程】项目任务列表导出完毕，请点击下载
                    <a
                      href="#"
                      class="font"
                      referrerpolicy="origin"
                      >点击此处下载</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月10日 14:47</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f79c6f; text-align: center"
                  original-title="app主管"
                  data-body="app主管"
                  data-original-title=""
                  >app</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- app主管 -->
                    <span>app主管</span>
                  </a>

                  <span class="content_ml">
                    将项目
                    <strong>新手项目使用教程-副本</strong> 中的里程碑<strong
                      >last milestone</strong
                    >起止时间2022-08-20-2022-08-20修改为2022-05-20-2022-06-16
                    <a
                      target="_blank"
                      href="#"
                      >查看详情</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月10日 09:38</div>
              </div>
            </div>
          </li>
          <li class="list_ml__item">
            <div class="list_mll tu-clearfix">
              <div class="img_list">
                <span
                  class="radius_img30"
                  style="background-color: #f79c6f; text-align: center"
                  original-title="app主管"
                  data-body="app主管"
                  data-original-title=""
                  >app</span
                >
              </div>
              <div class="text_list">
                <span>
                  <a
                    class="name_ml"
                    style="cursor: default"
                    href="javascript:void(0);"
                  >
                    <!-- app主管 -->
                    <span>app主管</span>
                  </a>

                  <span class="content_ml">
                    将项目
                    <strong>新手项目使用教程-副本</strong>
                    中的里程碑<strong>fasdfasdf</strong>起止时间2022-05-28-2022-08-19修改为2022-05-28-2022-06-09
                    <a
                      target="_blank"
                      href="#"
                      >查看详情</a
                    >
                  </span>
                </span>
                <div class="date_ml">6月10日 09:36</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
      name: 'IsreadStatistics',
      components: {},
      data() {
        return {

        }
      }

  };
</script>
<style scoped>
  .list-container {
    margin-top: 14px;
  }
  .list_ml {
    *zoom:1;font-size: 14px;
    line-height: 16px;
    font-family: "Helvetica Neue",Tahoma,"PingFang SC","Hiragino Sans GB",SimSun,STHeitiSC,sans-serif
}

.list_ml:after {
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    content: "."
}

.list_ml__item {
    padding: 10px;
    border-bottom: 1px solid #E9ECF0;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden
}

.list_ml__item:hover {
    background: #f7f8fa
}

.list_ml__item.active {
    background: #f7f8fa
}

.list_ml__item .date_ml {
    display: block;
    color: #aaa;
    margin-top: 3px;
    font-size: 12px;
    line-height: 14px;
    font-family: "Helvetica Neue",Tahoma,"PingFang SC","Hiragino Sans GB",SimSun,STHeitiSC,sans-serif
}

.list_ml__item .list_mll {
    width: 100%
}

.list_ml__item .img_list {
    float: left;
    display: inline-block;
    vertical-align: top;
    width: 5%
}

.list_ml__item .text_list {
    display: inline-block;
    width: 95%;
    float: left
}

.list_ml__item .text_list .sub-title {
    padding-right: 8px;
    color: #3e4e59
}

.list_ml__item .text_list a {
    margin-left: 20px;
    color: #2879ff
}

.list_ml__item .text_list a.notice-task-delete {
    margin-left: 0
}

.list_ml__item .text_list a:hover {
    color: #5c8eff
}

.list_ml__item .text_list .name_ml {
    margin-right: 10px;
    margin-left: 0;
    color: #2879ff
}

.list_ml__item .text_list .content_ml {
    line-height: 22px;
    word-break: break-all;
    word-wrap: break-word;
    font-size: 14px;
    color: #3F4755
}

.list_ml__item .text_list .content_ml strong {
    color: #141C28;
    padding: 0 5px
}

.list_ml__item .text_list .content_ml.ereport a {
    display: none
}

.list_ml__item .text_list .daiban {
    color: #0a1319;
    font-weight: 500
}

.list_ml__item .thk-btn {
    display: inline-block;
    height: 34px;
    line-height: 34px;
    padding: 0 20px;
    margin-left: 50px;
    background: #32a532;
    border: 0;
    color: #fff;
    border-radius: 3px
}

.list_ml__item .thk-btn.thk-btn-disabled {
    background: #c1e4c1;
    cursor: default
}

.list_ml .message-extend {
    line-height: 24px;
    font-size: 12px;
    color: #3E4E59
}

.list_ml .message-extend__value {
    padding-right: 4px
}

.list_ml .message-extend__key {
    padding-right: 4px;
    color: #8F9CA6
}

.list_ml .message-extend__strong {
    font-weight: 700
}

.list_ml .message-extend__a1 {
    color: #F28D49
}
  .radius_img30 {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: inline-block;
    overflow: hidden;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    color: #fff;
}
</style>
